.cm-reports {
  .cm-btn {
    span {
      padding-right: 30px;
    }

    .cm-active-polygon {
      clip-path: polygon(100% 0, 44% 47%, 100% 100%);
      background-color: white;
      width: 40px;
      right: -1px;
      top: 0;
      bottom: 0;
    }
  }
}

.ant-table-thead {
  .ant-table-cell {
    background-color: #4285F4;
    color: #ffffff;
  }
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  justify-content: start;
}

.demo-option-label-item>span {
  margin-right: 6px;
}

.ant-table-thead .ant-table-cell {
  white-space: nowrap !important;
}

.ant-table table {
  background: white !important;
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  justify-content: center !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0.375rem !important;
}

// .description{
//   padding-left: 200px;
// }
.cm-active-polygon {
  clip-path: polygon(100% 0, 44% 47%, 100% 100%);
  background-color: white;
  width: 40px;
  right: -1px;
  top: 0;
  bottom: 0;
}

.report-list1 {
  width: 280px;
  height: 455px;
  overflow-y: scroll;
  overflow-x: hidden;

}

.col2-md-4 {
  margin-top: 15px;
}

.main-12 {
  height: 455px;
}

@media (max-width: 1024) {
  .container-1 {
    padding-left: 0px;
  }
}

.input-height {
  height: 40px;
}

.slot-width {
  width: 100%;
}

.email-width {
  width: 50%;
}

@media (max-width: 768px) {
  .email-width {
    width: 100% !important;
  }
}

// @media (min-width: 425px) { 
//   .slot-width {
//     width: 160px !important;
//   }
// }

.w-slot {
  width: 50%;
}

@media (max-width: 576px) {
  .w-slot {
    width: 100% !important;
  }
}

.c-width {
  // height: 35px !important;
  width: 140px !important;
}

.rmdp-input {
  height: 30px !important;
}

.addremove-slot-margin {
  margin-top: 0 !important;
}

@media (max-width: 1027px) {
  .addremove-slot-margin {
    margin-top: 0.5rem !important;
  }
}


.addremove-slot-column {
  flex-direction: row;
}

@media (max-width: 1000px) {
  .addremove-slot-column {
    flex-direction: column !important;
  }
}

.export-width {
  width: none !important;
}

@media (max-width: 575px) {
  .export-width {
    width: 100% !important;
  }
}

.datePicker-margin {
  margin-top: 0 !important;
}

@media (max-width: 575px) {
  .datePicker-margin {
    margin-top: 0.5rem !important;
  }
}

.datePicker-width {
  width: 100% !important;
  height: 31px !important;
}

@media (max-width: 575px) {
  .datePicker-width {
    width: 50% !important;
  }
}

@media (max-width: 425px) {
  .datePicker-width {
    width: 80% !important;
  }
}

@media (max-width: 375px) {
  .export-flex {
    flex-direction: column !important;
    margin-top: 5px;
  }
}

@media (max-width: 375px) {
  .export-margin {
    margin-top: 14px !important;
  }
}

.btn-width {
  width: 40% !important;
}

input[type=file]::file-selector-button {
  border: 2px solid #0d6efd;
  padding: .5em .9em;
  border-radius: .5em;
  background-color: #0d6efd;
  transition: 1s;
  color: white;
}

// input[type=file]::file-selector-button:hover {
//   background-color: #81ecec;
//   border: 2px solid #00cec9;
// }

.disabled {
  pointer-events: none; //This makes it not clickable
  opacity: 0.6; //This grays it out to look disabled
}

.disabled-link {
  opacity: 0.6; //This grays it out to look disabled
}

.ant-select-dropdown {
  z-index: 1500;
}

.first-file-name-row {
  background-color: #909ca7;
}

.custom-table th.ant-table-cell  {
  line-height: .5 !important;
} 