.pricing-header {
    max-width: 700px;
}

.header-height {
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.align-top {
    vertical-align: top;
}

.border-gradient {
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
}

.border-gradient-blue {
    border-image-source: linear-gradient(to left, #3365FF, #00B7FF);
}