.cloud-mapper {
  height: 60px;
  width: 225px;
}

.know-more {
  text-decoration: none;
  padding: 10px 20px;
  display: inline-block;
  margin-top: 10px;
  border-radius: 10px;
  color: #2e3a4c;
  background-color: #3386ff;
  opacity: 75%;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  width: 150px;
  text-align: center;
  box-shadow: 0 20px 50px -10px rgba(90, 138, 207, 1);
}

.login-but {
  background: #3386ff;
  padding: 10px;
  border: none;
  margin-top: 20px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  box-shadow: 0 10px 50px -10px rgb(92, 155, 241);
}

.menu-font {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.para-size {
  font-size: 18px;
}

.border-gradient {
  border-bottom: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
}

.border-gradient-blue {
  border-image-source: linear-gradient(to left, #3365FF, #00B7FF);
}

// #home,
// #aboutUs,
// #contactUs {
//   padding-top: 40px;
//   /* Adjust based on navbar height */
// }

.image-hieght {
  height: 400;
}

@media only screen and (max-width: 600px) {
  .image-height {
    height: 500;
  }
}