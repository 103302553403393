// .title-d {
//   font-size: 2rem !important;
//   font-weight: 800 !important;
//   color: #3385ffbf;
// }

.chart-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  color: rgba(0, 0, 0, 0.5413);
}

.deleted-bg {
  background: #6cb4e8;

  p {
    font-size: 48px;
  }
}

.deleted-plus {
  border: 1px solid;
  border-color: #b9b9b9;
  border-style: dashed;
  border-radius: 7px;
  background: #f5f5f5;
}

.cm-connection-status {
  height: 15px;
  width: 15px;
  border-radius: 100px;
}

.column-select {
  height: 29px;
  padding: 0 4px 0 4px;
  background: white;
  border: 1px solid #dee2e6;
}

.dropdown-item:active {
  background-color: white !important;
}

.dropdown-item:hover {
  background-color: white !important;
}

.widgets-drop {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1px 3px 1px 3px;
}

.image-wh {
  height: 275px;
  width: 100%;
}

// @media (min-width: 992px) {
//   .col-lg-6 {
//     width: 48% !important;
//   }
// }
// @media (min-width: 992px) {
//   .col-lg-3 {
//     width: 30% !important
//   }
// }