// Font family initialization
// @import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,700,600,400);

// @tailwind base;
// @tailwind components;
// @tailwind utilities;

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  background: none;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #0000002f;
  border-radius: 100px;
}

$theme-colors: (
  "cm-primary": #4285f4,
  "cm-light-primary": #f0f6fc,
  "cm-active-primary": #6cb4e8,
  "cm-light-text": #0000008a,
  "cm-extralight-text": #00000061,
  "cm-success": #6ce887,
  "cm-danger": #ff0000,

  "cm-primary1": #3386ff,//Capitol Tunnels blue
  //opacity 100
  "cm-light-primary1": #e7f0ff,
  //opacity 100
  "cm-active-primary1": #3385ffbf,
  //opacity 75
  "cm-extralight": #e7f0ff,
  //opacity 30
  "cm-dark": #4d4d4d,
  "cm-light": #e7f0ff4d, 
  "cm-dark-grey": #373737 ,
  "cm-medium-grey": #7f7f7f,
  "cm-light-grey": #bbbec0,
  "cm-icon-grey": #c9c9c9,
  "cm-accent-grey": #e3e3e2,
  "cm-other-grey": #d1d2d2,
  "near-black-accent": #030303,
  // opacty 100
  "cm-dark-blue": #2E3A4C,
  //opacity 100
  "bg-primary": #3386FF,
);

// Dynamically generated CSS
@each $color in $theme-colors {
  .text-#{nth($color, 1)} {
    color: nth($color, 2);
  }

  .bg-#{nth($color, 1)} {
    background: nth($color, 2);
  }
}

@function getColor($name) {
  @return map-get($theme-colors, $name);
}

// Reusable CSS
.cursor-pointer {
  cursor: pointer;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-auto {
  overflow: auto !important;
}

// cm- is the prefix to annotate custom CSS used in the project
// Sidebar Layout CSS
.cm-sidebar-layout {
  overflow-x: hidden;

  .cm-sidebar {
    transition: width 0.5 ease-out;

    .cm-menu-toggler {
      right: -10px;
      background: getColor("cm-primary");
      color: white;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      outline: 4px solid getColor("cm-light-primary");
    }

    .cm-navigation {
      .cm-btn {
        &:hover {
          background: #0000001f;
        }

        &.active {
          .cm-nav-icon {
            background: getColor("cm-primary") !important;
            color: white !important;
            border: 0 !important;
          }

          .cm-nav-text {
            color: getColor("cm-primary") !important;
          }
        }

        .cm-nav-icon {
          height: 48px;
          width: 48px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid getColor("cm-light-text") !important;

          &.cm-nav-icon-sm {
            height: 25px;
            width: 25px;
          }
        }
      }

      @media (max-width: 576px) {
        padding: 0 !important;

        .cm-btn {
          h6 {
            display: none;
          }
        }
      }
    }

    @media (max-width: 576px) {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      transform: translate3d(0, 0, 100%);
      z-index: 1;
      width: 70px;
      min-width: auto !important;

      .cm-menu-toggler {
        display: none;
      }
    }
  }

  .cm-header {
    .cm-search-bar {
      width: 100%;
      max-width: 614px;
      height: 30px;
      border-radius: 10px;
      padding: 0px 16px 0px 16px;
      outline: none;
      border: none;
    }

    .cm-notification-icon {
      background-color: white;
      border-radius: 100px;
      height: 36px;
      width: 36px;

      .cm-badge {
        border-radius: 100px;
        height: 18px;
        min-width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        top: -5px;
        right: -5px;
      }
    }
  }

  @media (max-width: 576px) {
    .cm-wrapper {
      margin-left: 70px;
    }
  }
}

.title-d {
  font-size: 2rem !important;
  font-weight: 800 !important;
  color: #3385ffbf;
}

.ant-table-thead .ant-table-cell {
  font-weight: 900;
  font-size: 14px;
  // background-color: #0d6efd !important;
  background-color: #4285f4 !important;
  color: #ffffff !important;
}

.logo-width {
  width: 100%;

  @media (max-width: 576px) {
    width: 150% !important;
  }
}

.ant-table table { font-size: 16px; }

// .apexcharts-menu-icon {
//   display: none;
// }

.apexcharts-legend-text {
  font-size: 14px !important;
}

// .apexcharts-toolbar {
//   left: -7px !important;
//   right: 0px !important
// }

.ant-menu-title-content {
  font-size: 18px !important;
}

// .ant-table-tbody > tr:nth-child(odd) {
//   background-color: #d4d4d4;
// }

// .ant-table-tbody > tr:nth-child(even) {
//   background-color: #e9e9e9;
// }
.my-active-row {
  background-color: #9fb7c9;
}

.rd3t-link {
  fill: none;
  stroke: #ada4a4;
}