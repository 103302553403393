.added{
    background-color:#D9D9D9;
    border: 1px solid#D9D9D9 ;
    border-radius: 20px;
    font-size: 16px;
    color:#0000008A ;
}
.Active{
    background-color:#1BCB80;
    border: 1px solid#1BCB80 ;
    border-radius: 20px;
    font-size: 16px;
    color: #FFFFFF ;
    text-align: center;
}
.Inactive{
    background-color:#FF0000;
    border: 1px solid#FF0000 ;
    border-radius: 20px;
    font-size: 16px;
    color: #FFFFFF ;
    text-align: center;
}
.flex-auto{
    flex: auto;
}
.text-align-center{
    text-align: center;
}

.upload-image {
    border: 1px solid transparent;
    border-radius: 50%;
    height: 150px;
    width: 150px;
}
.cursor-pointer {
    cursor: pointer;
  }