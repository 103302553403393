.search-click {
  border: none;
  outline: none;
  background-size: 22px;
  background-position: 13px;
  border-radius: 5px;
  width: 25px;
  height: 30px;
  padding-left: 30px;
  transition: all 0.5s;
}
.search-click:focus {
  width: 290px;
  // padding-left: 50px;
  background-color: white;
  align-items: center;
  background-repeat: no-repeat;
}
.search-click {
  position: relative;
  overflow: hidden;
  background: transparent;
  height: 30px;
  background-image: url(../assets/icons/search-icon.svg);
  padding: 5px 0 5px 30px;
  background-repeat: no-repeat;
}
.search-click input {
  background: transparent;
  outline: none;
  position: absolute;
  width: 290px;
  height: 30px;
  left: 0%;
  padding: 10px;
}
.sheader {
  height: 80px;
  background-color: #ffffff;
}
.sapage{
  padding:20px;
  background-color: #F5F5F5;
}
.cm-notification1 {
  background-color: white;
  border-radius: 100px;
  height: 36px;
  width: 36px;
}
  .cm-badge1 {
    border-radius: 100px;
    height: 18px;
    min-width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    top: -5px;
    right: -5px;
  }